import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  LaunchModal,
  PageTitle,
  SpecificContactForm,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

// eslint-disable-next-line
import rowImage from '../../queries/images/row-image'


const SixtiethAnniversaryPage = (props) => {
  return (
    <Layout>
      <SEO
        title="Supporter Spotlights"
        description="BSCS Spotlights its Supporters and Donors"
        canonical="https://bscs.org/donate/supporter-spotlights/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/donate">Donate</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Supporter Spotlights
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>Supporter Spotlights</PageTitle>
        <TitleBorder />
        <div className="flex flex-wrap-reverse mb-5">
          <div className="w-full lg:w-3/5 xl:w-2/3">
            <section>
              <p>
                As an independent nonprofit, BSCS Science Learning relies on grants and donations to tackle the toughest challenges in science education. We are grateful for our many supporters, who are direct contributors to the more-inclusive, meaningful, and effective science education that we’re actively pursuing.
              </p>

              <p>
                What is it that keeps our supporters so loyal to BSCS?
              </p>

              <p>
                Genie Scott started donating to BSCS in the 90’s as a board member, and has continued giving ever since. Today, we spotlight her affinity toward BSCS.
              </p>

              <p>
                We look forward to filling this page with supporter spotlights.
              </p>
            </section>
            <section id="katherine-and-randall-backe-story" className="sixty-section rounded-top p-4 mt-5">
              <div className="sixty-title-row">
                <h2 className="sixty-title mb-0">Genie Scott</h2>
                <p className="mb-0" style={{ fontFamily: '"Lora", "Adobe Blank"', fontStyle: 'italic' }}>BSCS Board Member (1993-1999)</p>
              </div>
              <hr className="mt-2" />
              <div>
                <Img
                  className="float-right ml-3 mb-3 rounded"
                  fixed={props.data.genie_scott.childImageSharp.fixed}
                  alt=""
                />
                <p>What first comes to mind when I think of BSCS is integrity. It’s the organization that returned evolution to high school textbooks in the 1960s, and never knuckled under to the pressures exerted by “adoption states” to water down the subject, or qualify it in some way – as routinely was done by some publishers. It’s the organization that stayed true to its core to not just publish scientifically accurate instructional materials, but to challenge teachers and their students with innovative approaches to teaching and learning, and do it over and over, for more than 60 years, now.</p>

                <p>And what comes to mind for me personally is how much I cherish having been able to learn from the smart and creative administration and staff of BSCS, including (but not limited to) the late Bill Mayer, Joe McInerney, Rodger Bybee, Janet Carlson – too many to list! </p>

                <p>Biology education is greatly enriched due to BSCS innovations. And they’re just getting started!</p>
              </div>
            </section>
          </div>
          <div className="w-full lg:w-2/5 xl:w-1/3">
            <VerticalCard>
              <VerticalCardBody>
                <h5 className="px-3">Are you a BSCS Supporter?</h5>
                <div className="flex justify-center">
                  <LaunchModal
                    className="bg-white"
                    launchText="Tell us why!"
                    modalId="bscs-supporter-spotlight-modal"
                    title="Contact"
                  >
                    <SpecificContactForm person="Lauren Novo" infoat={true} />
                  </LaunchModal>
                </div>
                <div className="flex justify-center">
                  <Link to="/donate">
                    <Button className="btn btn-primary mt-4">Donate now</Button>
                  </Link>
                </div>
              </VerticalCardBody>
            </VerticalCard>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    genie_scott: file(relativePath: { eq: "supporter-spotlights/genie_scott.jpg" }) {
      childImageSharp {
        fixed(height: 230) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default SixtiethAnniversaryPage
